import { PERMISSION_STATE } from "@letsbit/malcolmlb";
import React, { ReactNode } from "react";
import { createVoidContext } from "utils/voidContext";

interface PermissionsContext {
  permission: PERMISSION_STATE;
}

export const PermissionsContext = React.createContext<PermissionsContext>(
  createVoidContext("permission-context"),
);

export const PermissionsProvider: React.FC<{
  children: ReactNode;
  permission: PERMISSION_STATE;
}> = ({ children, permission }) => {
  return (
    <PermissionsContext.Provider value={{ permission }}>
      {children}
    </PermissionsContext.Provider>
  );
};
