import { defineMessages } from "react-intl";

export const scope = "pages.SelectAccountPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Elige una cuenta",
  },
  carouselTitle: {
    id: `${scope}.carouselTitle`,
    defaultMessage: "Soluciones para tu empresa",
  },
  carouselMessage: {
    id: `${scope}.carouselMessage`,
    defaultMessage:
      "Con Let’sBit vas a poder simplificar cobros y pagos internacionales, resguardar reservas a través de dólares bancarios y generar rendimientos de tus reservas.",
  },
});
