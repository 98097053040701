import { Card } from "@letsbit/milkylb";
import { useGetAccess } from "hooks/hodor/useGetAccess";
import { useRotateSession } from "hooks/hodor/useRotateSession";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as SimpleArrowIcon } from "assets/images/icons/simple-arrow.svg";
import { messages } from "./messages";
import { AccountSelectorProps } from "./types";
import LoadingAnimation from "components/LoadingAnimation";

import "./styles.scss";

export const AccountSelector: React.FC<AccountSelectorProps> = ({
  onDidClick,
  onWillClick,
}) => {
  const { data } = useGetAccess();
  const { rotateSession, loading } = useRotateSession();
  const { formatMessage } = useIntl();

  const individuals = data?.filter((d) => d.type !== "org");

  const organizations = data?.filter((d) => d.type === "org");

  const onClick = async (uid: string) => {
    onWillClick?.();
    await rotateSession(uid);
    onDidClick?.();
  };
  return (
    <div className="account-selector flex w-full flex-col items-start justify-center">
      {loading && <LoadingAnimation />}
      <h1 className="mb-4 text-lg font-bold text-[--milkylb-color-neutral-600]">
        <FormattedMessage
          {...messages.personalAccount}
          values={{ br: <br /> }}
        />
      </h1>
      {individuals &&
        individuals.map((i, k) => (
          <Card
            className="mb-2 w-full"
            key={`individuals-${k}`}
            title={i.name}
            subtitle={
              "Cuenta personal - " + formatMessage(messages[i.permission])
            }
            icon={<img alt="" />}
            onClick={() => onClick(i.uid)}
          >
            <SimpleArrowIcon className="account-selector__icon" />
          </Card>
        ))}
      <h1 className="mb-4 mt-2 text-lg	font-bold text-[--milkylb-color-neutral-600]">
        <FormattedMessage
          {...messages.enterpriseAccount}
          values={{ br: <br /> }}
        />
      </h1>
      {organizations &&
        organizations.map((i, k) => (
          <Card
            className="mb-2 w-full"
            key={`organizations-${k}`}
            title={i.name}
            subtitle={
              "Cuenta empresa - " + formatMessage(messages[i.permission])
            }
            icon={<img alt="" />}
            onClick={() => onClick(i.uid)}
          >
            <SimpleArrowIcon className="account-selector__icon" />
          </Card>
        ))}
    </div>
  );
};
