import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { StepsPage } from "./StepsPage";
import { CompanyDataRoutes } from "./CompanyDataRoutes";
import { useForm } from "react-hook-form";
import {
  CustomerData,
  INFORMATION_REQUEST_STATUS,
  JuridicalPersonDataForm,
  VERIFICATION_ENTERPRISE_STEPS,
} from "@letsbit/malcolmlb";
import { useCreateCustomer } from "hooks/user/useCreateCustomer";
import LoadingAnimation from "components/LoadingAnimation";
import { useCreateJuridicalPersonData } from "hooks/user/useCreateJuridicalPersonData";
import { useEffect } from "react";
import { useGetOnBoardingSteps } from "hooks/user/useGetOnBoardingSteps";
import { StepsCompletition } from "./types";
import { errorParser } from "utils/defaultError";
import { SuccessPage } from "./SuccessPage";
import { CompanyNationalIdPage } from "./CompanyNationalIdPage";
import { InformationRequestsRoutes } from "./InformationRequestsRouter";
import { CompanyRepresentativePage } from "./CompanyDataRoutes/CompanyRepresentativePage";
import { RepresentativeForm } from "../components/forms/RepresentativeForm/types";

export const OnBoardingForeignCompaniesRoutes: React.FC = () => {
  const navigate = useNavigate();

  const companyForm = useForm<JuridicalPersonDataForm>({});
  const { loading: creatingCustomer, createCustomer } = useCreateCustomer();
  const { loading: creatingJPD, createJuridicalPersonData } =
    useCreateJuridicalPersonData();
  const { data, isLoading: loadingSteps, mutate } = useGetOnBoardingSteps();

  useEffect(() => {
    if (data?.step === VERIFICATION_ENTERPRISE_STEPS.ENDED) {
      navigate("/logged/dashboard");
    }
  });

  const completed: StepsCompletition = {
    customer: !!data?.customer,
    director_email: !!(
      data?.jpd ||
      (companyForm.getValues().director_member_email &&
        companyForm.getValues().director_name)
    ),
    jpd: !!data?.jpd,
    extra_docs:
      data?.step === VERIFICATION_ENTERPRISE_STEPS.ENDED ||
      (data?.information_requests && data?.information_requests.length > 0
        ? data?.information_requests?.reduce(
            (cond, req) =>
              !(
                req.status === INFORMATION_REQUEST_STATUS.AWAITING_MEMBER ||
                req.status === INFORMATION_REQUEST_STATUS.NOTIFY_ADMIN
              ) && cond,
            true,
          )
        : false),
  };

  const onContinue = () => {
    switch (data?.step) {
      case VERIFICATION_ENTERPRISE_STEPS.CUSTOMER_DATA:
        navigate("./company-national-id");
        break;
      case VERIFICATION_ENTERPRISE_STEPS.JURIDICAL_DATA:
        if (
          companyForm.getValues().director_member_email &&
          companyForm.getValues().director_name
        ) {
          navigate("./company-data/company-name");
        } else {
          navigate("./company-representative");
        }
        break;
      case VERIFICATION_ENTERPRISE_STEPS.EXTRA_DOCS:
        navigate("./information-requests");
        break;
      default:
        break;
    }
  };

  const onSubmitCustomerData = async (customerData: CustomerData) => {
    try {
      await createCustomer(customerData);

      navigate("/logged/onboarding/foreign");
    } catch (error) {
      switch (errorParser(error)) {
        case "member.customer.customer_already_taken":
          navigate("/logged/onboarding/error/already-registered-customer");
          return;
        default:
          return;
      }
    }
  };

  const onSubmitRepresentativeEmail = (form: RepresentativeForm) => {
    companyForm.setValue("director_member_email", form.director_member_email);
    companyForm.setValue("director_name", form.director_name);

    navigate("/logged/onboarding/foreign");
  };

  const onSubmitJuridicalPersonData = async () => {
    const jpd = companyForm.getValues();

    await createJuridicalPersonData(jpd);
    navigate("/logged/onboarding/foreign");
  };

  return (
    <>
      {(creatingCustomer || creatingJPD || loadingSteps) && (
        <LoadingAnimation />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <StepsPage
              reloadSteps={mutate}
              completed={completed}
              onContinue={onContinue}
            />
          }
        />
        <Route path="/success" element={<SuccessPage />} />
        <Route
          path="/company-national-id"
          element={<CompanyNationalIdPage onSubmit={onSubmitCustomerData} />}
        />
        <Route
          path="/company-representative"
          element={
            <CompanyRepresentativePage onSubmit={onSubmitRepresentativeEmail} />
          }
        />
        <Route
          path="/company-data/*"
          element={
            <CompanyDataRoutes
              form={companyForm}
              onSubmit={onSubmitJuridicalPersonData}
            />
          }
        />

        <Route
          path="/information-requests/*"
          element={
            <InformationRequestsRoutes
              reloadSteps={mutate}
              informationRequests={data?.information_requests}
              juridicalPersonData={data?.jpd}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/logged/onboarding/foreign" replace={true} />}
        />
      </Routes>
    </>
  );
};
