import { PermissionsContext } from "providers/PermissionProvider";
import React, { PropsWithChildren, useContext } from "react";
import { ComponentWithPermissionProps } from "./types";
import { WRITE_PERMISSIONS } from "components/RouteWithPermission";

export const ComponentWithPermission: React.FC<
  ComponentWithPermissionProps
> = ({ permissions, children }) => {
  const { permission: userPermission } = useContext(PermissionsContext);
  if (permissions.includes(userPermission)) {
    return children;
  }

  return null;
};

export const ComponentWithPermissionAdmin: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ComponentWithPermission
      permissions={WRITE_PERMISSIONS}
      children={children}
    />
  );
};
