import { PERMISSION_STATE } from "@letsbit/malcolmlb";
import { defineMessages } from "react-intl";

export const scope = "components.AccountSelector";

export const messages = defineMessages({
  personalAccount: {
    id: `${scope}.personalAccount`,
    defaultMessage: "Mi cuenta personal",
  },
  enterpriseAccount: {
    id: `${scope}.enterpriseAccount`,
    defaultMessage: "Cuentas de empresas",
  },
  [PERMISSION_STATE.OWNER]: {
    id: `${scope}.${PERMISSION_STATE.OWNER}`,
    defaultMessage: "Representante Legal",
  },
  [PERMISSION_STATE.ADMIN]: {
    id: `${scope}.${PERMISSION_STATE.ADMIN}`,
    defaultMessage: "Administrador",
  },
  [PERMISSION_STATE.VIEWER]: {
    id: `${scope}.${PERMISSION_STATE.VIEWER}`,
    defaultMessage: "Solo lectura",
  },
  [PERMISSION_STATE.REVOKED]: {
    id: `${scope}.${PERMISSION_STATE.REVOKED}`,
    defaultMessage: "Eliminado",
  },
});
