import React, { ReactNode, useCallback, useRef, useState } from "react";
import { createVoidContext } from "utils/voidContext";
import ReCAPTCHA from "react-google-recaptcha";

interface ReCaptchaContext {
  recaptchaRef: React.RefObject<ReCAPTCHA>;
  getToken: () => Promise<string | null>;
  loading: boolean;
}

export const ReCaptchaContext = React.createContext<ReCaptchaContext>(
  createVoidContext("recaptcha-context"),
);

export type Props = {
  children: ReactNode;
};

const ReCaptchaProvider = (props: Props) => {
  const { children } = props;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false);

  const getToken = useCallback(async () => {
    setLoading(true);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      const token = await recaptchaRef.current.executeAsync();
      setLoading(false);
      return token;
    }

    setLoading(false);
    return null;
  }, [recaptchaRef]);

  const contextValue = {
    recaptchaRef,
    getToken,
    loading,
  };

  return (
    <ReCaptchaContext.Provider value={contextValue}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        // sitekey={window.env.captcha.siteKey}
        sitekey={
          "Cypress" in window
            ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            : (window as Window & typeof globalThis).env.captcha.siteKey
        }
      />
      {children}
    </ReCaptchaContext.Provider>
  );
};

export default ReCaptchaProvider;
