import { UserAccess } from "@letsbit/malcolmlb";
import { useContext } from "react";
import { SWRResponse } from "swr";

import { ApiContext } from "providers/ApiProvider";
import { useAuthSWR } from "hooks/utils/useAuthSWR";

export const SWR_GET_MEMBER_GET_ACCESS = "get-member-access";

export const useGetAccess = (): SWRResponse<UserAccess[]> => {
  const { memberInstance } = useContext(ApiContext);
  const swr = useAuthSWR(SWR_GET_MEMBER_GET_ACCESS, async () => {
    const { data } = await memberInstance.hodor.getAccess();

    return data;
  });

  return swr;
};
