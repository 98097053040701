import { defineMessages } from "react-intl";

export const scope = "pages.InvalidPermissionsPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "No tienes  los permisos para acceder a esta página",
  },
  desc: {
    id: `${scope}.desc`,
    defaultMessage:
      "Contacta con el administrador de tu equipo para que actualice los permisos necesarios para visualizar la página.",
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: "VOLVER AL INICIO",
  },
});
