import classNames from "classnames";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { UserContext } from "providers/UserProvider";

import { NavBarButtonProps } from "./types";

import { ReactComponent as ChatIcon } from "assets/images/icons/navbar/chat.svg";
import { ReactComponent as HelpIcon } from "assets/images/icons/navbar/help.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/navbar/logout.svg";
import { ReactComponent as DepositIcon } from "assets/images/icons/navbar/deposit.svg";
import { ReactComponent as DollarizeIcon } from "assets/images/icons/navbar/dollarize.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/navbar/home.svg";
import { ReactComponent as MultipleIcon } from "assets/images/icons/navbar/multiple.svg";
import { ReactComponent as ProfileIcon } from "assets/images/icons/navbar/profile.svg";
import { ReactComponent as CardIcon } from "assets/images/icons/navbar/card.svg";
import { ReactComponent as SwapIcon } from "assets/images/icons/navbar/swap.svg";
import { ReactComponent as TransferIcon } from "assets/images/icons/navbar/transfer.svg";
import { ReactComponent as LogoIcon } from "assets/images/icons/navbar/logo.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/navbar/wallet.svg";
import { ReactComponent as HistoryIcon } from "assets/images/icons/navbar/history.svg";
import { messages } from "./messages";
import { LocationContext } from "providers/LocationProvider";
import { useGetUserCountry } from "hooks/user/useGetUserCountry";
import { useGetServicesConfig } from "hooks/user/useGetServicesConfig";
import { useIntercomCustom } from "hooks/utils/useIntercomCustom";
import { ComponentWithPermissionAdmin } from "components/ComponentWithPermission";

const NavBarButton: React.FC<NavBarButtonProps> = ({
  icon,
  children,
  active,
  color = "--milkylb-color-secondary-100",
  ...props
}) => {
  return (
    <button
      className={classNames(
        `flex w-full items-center rounded-lg px-2 transition-all`,
        !active && "hover:bg-[--color-navbar-hover]",
      )}
      style={{
        color: `var(${color})`,
      }}
      {...props}
    >
      <span
        className={classNames(
          "mr-4 p-2",
          active && "rounded-full bg-[--color-alternative-accent]",
        )}
      >
        {icon}
      </span>
      {children}
    </button>
  );
};

export const NavBar: React.FC = () => {
  const { logout } = useContext(UserContext);
  const { show } = useIntercomCustom();
  const { location } = useContext(LocationContext);
  const country = useGetUserCountry();
  const { data: servicesConfig } = useGetServicesConfig();

  return (
    <nav
      className={classNames(
        "fadeIn w-[calc((100vw - 60px) / 12)] flex min-w-[237px] flex-col justify-between bg-[--milkylb-color-secondary-800] px-6 py-8",
        !location.pathname.startsWith("/logged") && "fadeOut",
      )}
    >
      <div className="flex flex-col gap-1">
        <LogoIcon className="mb-8 ml-2 flex h-8 w-8 items-center justify-center" />
        <Link to="/logged/dashboard">
          <NavBarButton
            icon={<HomeIcon className="h-5 w-5" />}
            active={location.pathname.startsWith("/logged/dashboard")}
          >
            <FormattedMessage {...messages.homeLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/balance">
          <NavBarButton
            active={location.pathname.startsWith("/logged/balance")}
            icon={<WalletIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.balanceLabel} />
          </NavBarButton>
        </Link>
        <ComponentWithPermissionAdmin>
          <Link to="/logged/exchange">
            <NavBarButton
              active={location.pathname.startsWith("/logged/exchange")}
              icon={<SwapIcon className="h-5 w-5" />}
            >
              <FormattedMessage {...messages.swapLabel} />
            </NavBarButton>
          </Link>
        </ComponentWithPermissionAdmin>
        <Link to="/logged/deposit">
          <NavBarButton
            active={location.pathname.startsWith("/logged/deposit")}
            icon={<DepositIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.depositLabel} />
          </NavBarButton>
        </Link>
        <ComponentWithPermissionAdmin>
          <Link to="/logged/withdraw">
            <NavBarButton
              active={location.pathname.startsWith("/logged/withdraw")}
              icon={<TransferIcon className="h-5 w-5" />}
            >
              <FormattedMessage {...messages.withdrawLabel} />
            </NavBarButton>
          </Link>
        </ComponentWithPermissionAdmin>
        {/* TODO: We should remove this and do it properly */}
        {servicesConfig?.includes("multiples_withdraws") && (
          <ComponentWithPermissionAdmin>
            <Link to="/logged/multiples-withdraw">
              <NavBarButton
                active={location.pathname.startsWith(
                  "/logged/multiples-withdraw",
                )}
                icon={<MultipleIcon className="h-5 w-5" />}
              >
                <FormattedMessage {...messages.multipleLabel} />
              </NavBarButton>
            </Link>
          </ComponentWithPermissionAdmin>
        )}
        {servicesConfig?.includes("cards") && (
          <Link to="/logged/cards">
            <NavBarButton
              active={location.pathname.startsWith("/logged/cards")}
              icon={<CardIcon className="h-5 w-5" />}
            >
              <FormattedMessage {...messages.cardsLabel} />
            </NavBarButton>
          </Link>
        )}
        {country === "AR" && (
          <ComponentWithPermissionAdmin>
            <Link to="/logged/dollarize">
              <NavBarButton
                active={location.pathname.startsWith("/logged/dollarize")}
                icon={<DollarizeIcon className="h-5 w-5" />}
              >
                <FormattedMessage {...messages.dollarizeLabel} />
              </NavBarButton>
            </Link>
          </ComponentWithPermissionAdmin>
        )}
        <Link to="/logged/history">
          <NavBarButton
            active={location.pathname.startsWith("/logged/history")}
            icon={<HistoryIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.historyLabel} />
          </NavBarButton>
        </Link>
        <Link to="/logged/profile">
          <NavBarButton
            active={location.pathname.startsWith("/logged/profile")}
            icon={<ProfileIcon className="h-5 w-5" />}
          >
            <FormattedMessage {...messages.profileLabel} />
          </NavBarButton>
        </Link>
      </div>
      <div className="flex flex-col gap-1">
        <a href="https://help.letsbit.io/" target="_blank">
          <NavBarButton
            icon={<HelpIcon className="h-5 w-5" />}
            color="--milkylb-color-secondary-200"
          >
            <FormattedMessage {...messages.helpCenterLabel} />
          </NavBarButton>
        </a>
        <NavBarButton
          icon={<ChatIcon className="h-5 w-5" />}
          onClick={() => show()}
          color="--milkylb-color-secondary-200"
        >
          <FormattedMessage {...messages.contactLabel} />
        </NavBarButton>
        <NavBarButton
          icon={<LogoutIcon className="h-5 w-5" />}
          onClick={() => logout()}
          color="--milkylb-color-red-error"
        >
          <FormattedMessage {...messages.logOutLabel} />
        </NavBarButton>
      </div>
    </nav>
  );
};
