import { ReactComponent as InfoIconSvg } from "assets/images/icons/info.svg";
import classNames from "classnames";
import { ReactNode } from "react";

export const InfoIcon: React.FC<{
  children?: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={classNames("mb-2 flex items-center", className)}>
      <InfoIconSvg className="mr-2 h-[24px] w-[24px]" />
      <p className="text-xs font-semibold text-[--milkylb-color-neutral-400]">
        {children}
      </p>
    </div>
  );
};
