import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";
import { DividerWithCarousel } from "components/DividerWithCarousel";
import loginEnterprise from "assets/images/login_enterprise.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@letsbit/milkylb";
import { LogOutButton } from "components/LogOutButton";

export const InvalidPermissionsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <DividerWithCarousel
      carouselContent={[
        <div className="flex h-full w-full items-center justify-center p-8">
          <div className="flex w-full max-w-[500px] flex-col justify-center">
            <img src={loginEnterprise} />
          </div>
        </div>,
      ]}
      noDots
      noSwipe
    >
      <div className="relative flex h-full w-full flex-col items-center p-8">
        <div className="absolute right-8 top-8">
          <LogOutButton />
        </div>
        <div className="flex w-full max-w-md grow flex-col items-center justify-center">
          <h1 className="mb-6 w-full font-['Tomato_Grotesk'] text-3xl/8 font-bold text-[--milkylb-color-red-error]">
            <FormattedMessage {...messages.title} values={{ br: <br /> }} />
          </h1>
          <h4 className="w-full pb-20 text-lg/5 font-normal text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.desc} values={{ br: <br /> }} />
          </h4>

          <Button
            style={{ width: "100%" }}
            onClick={() => navigate("/logged/dashboard")}
          >
            <FormattedMessage {...messages.button} />
          </Button>
        </div>
      </div>
    </DividerWithCarousel>
  );
};
