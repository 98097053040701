import { Button } from "@letsbit/milkylb";
import { DividerWithCarousel } from "components/DividerWithCarousel";
import { FormattedMessage } from "react-intl";
// import { useNavigate } from "react-router-dom";
import { messages } from "./messages";
import { Stepper } from "../../components/Stepper";
import { StepsPageProps } from "../types";
import { SolutionContent } from "../../components/SolutionContent";
import { useEffect } from "react";
import { InfoIcon } from "components/InfoIcon";
import CustomFormattedMessage from "components/CustomFormattedMessage";

const steps = [
  {
    idx: "1",
    title: <FormattedMessage {...messages.step1Title} />,
  },
  {
    idx: "2",
    title: <FormattedMessage {...messages.step2Title} />,
  },
  {
    idx: "3",
    title: <FormattedMessage {...messages.step3Title} />,
    items: [
      <FormattedMessage {...messages.step3Desc2} />,
      <FormattedMessage {...messages.step3Desc3} />,
      <FormattedMessage {...messages.step3Desc4} />,
      <FormattedMessage {...messages.step3Desc5} />,
    ],
  },
  {
    idx: "4",
    title: <FormattedMessage {...messages.step4Title} />,
    items: [
      <FormattedMessage {...messages.step4Desc1} />,
      <FormattedMessage {...messages.step4Desc2} />,
    ],
  },
];

export const StepsPage: React.FC<StepsPageProps> = ({
  completed,
  onContinue,
  reloadSteps,
}) => {
  const completedArray = [
    completed.customer && "1",
    completed.director_email && "2",
    completed.jpd && "3",
    completed.extra_docs && "4",
  ].filter((x) => !!x);

  useEffect(() => {
    reloadSteps();
  }, [reloadSteps]);

  return (
    <DividerWithCarousel carouselContent={[<SolutionContent />]} noDots noSwipe>
      <div className="flex h-full w-full flex-col items-center justify-between p-24">
        <div className="flex w-full flex-col">
          <h1 className="pb-8 font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} />
          </h1>
          <p className="pb-4 text-sm font-normal text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.description} />
          </p>
          <Stepper steps={steps} completed={completedArray as string[]} />
        </div>
        <InfoIcon className="mt-3">
          <CustomFormattedMessage
            {...messages.info}
            values={{
              bold: <FormattedMessage {...messages.infoBold} />,
            }}
          />
        </InfoIcon>
        <Button className="self-end" onClick={onContinue}>
          <FormattedMessage {...messages.start} />
        </Button>
      </div>
    </DividerWithCarousel>
  );
};
