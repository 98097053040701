import { PermissionsContext } from "providers/PermissionProvider";
import React, { PropsWithChildren, useContext } from "react";
import { RouteWithPermissionProps } from "./types";
import { PERMISSION_STATE } from "@letsbit/malcolmlb";
import { Navigate } from "react-router-dom";

export const READ_PERMISSIONS = [
  PERMISSION_STATE.ADMIN,
  PERMISSION_STATE.OWNER,
  PERMISSION_STATE.VIEWER,
];

export const WRITE_PERMISSIONS = [
  PERMISSION_STATE.ADMIN,
  PERMISSION_STATE.OWNER,
];

export const RouteWithPermission: React.FC<RouteWithPermissionProps> = ({
  permissions,
  children,
}) => {
  const { permission: userPermission } = useContext(PermissionsContext);
  if (permissions.includes(userPermission)) {
    return children;
  }

  return <Navigate to="/logged/invalid-permissions" />;
};

export const RouteWithPermissionViewer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <RouteWithPermission permissions={READ_PERMISSIONS} children={children} />
  );
};

export const RouteWithPermissionAdmin: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <RouteWithPermission permissions={WRITE_PERMISSIONS} children={children} />
  );
};
