import { useContext, useState } from "react";
import { ApiContext } from "providers/ApiProvider";
import { FlowTypes } from "@letsbit/malcolmlb";
import { CSRFContext } from "providers/CSRFProvider";
import { UserContext } from "providers/UserProvider";

export const useRotateSession = (): {
  rotateSession: (uid: string) => Promise<void>;
  loading: boolean;
} => {
  const { memberInstance } = useContext(ApiContext);
  const { setCSRFToken } = useContext(CSRFContext);
  const { getUser, loading: userLoading } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const rotateSession = async (uid: string) => {
    setLoading(true);
    try {
      const { data: sessionData } = await memberInstance.hodor.rotateSession(
        uid,
        FlowTypes.BROWSER,
      );
      if (sessionData.csrf_token) {
        setCSRFToken(sessionData.csrf_token);
      }

      await getUser("/logged/dashboard");
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return { rotateSession, loading: loading || userLoading };
};
