import React from "react";
import { FormattedMessage } from "react-intl";

import { DividerWithCarousel } from "components/DividerWithCarousel";
import loginEnterprise from "assets/images/login_enterprise.svg";

import { messages } from "./messages";
import { LogOutButton } from "components/LogOutButton";
import { AccountSelector } from "components/AccountSelector";
import { useNavigate } from "react-router-dom";

export const SelectAccountPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <DividerWithCarousel
      carouselContent={[
        <div className="flex h-full w-full items-center justify-center p-8">
          <div className="flex w-full max-w-[500px] flex-col justify-center">
            <img src={loginEnterprise} />
            <h2 className="pb-4 text-center font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-0]">
              <FormattedMessage
                {...messages.carouselTitle}
                values={{ number: 2 }}
              />
            </h2>
            <h4 className="text-center text-base font-normal text-[--milkylb-color-neutral-0]">
              <FormattedMessage
                {...messages.carouselMessage}
                values={{
                  br: <br />,
                }}
              />
            </h4>
          </div>
        </div>,
      ]}
      noDots
      noSwipe
    >
      <div className="relative flex h-full w-full items-center justify-center">
        <LogOutButton className="absolute right-8 top-8" />
        <div className="my-24 flex w-full max-w-md flex-col items-start">
          <h2 className="pb-4 font-['Tomato_Grotesk'] text-3xl font-bold text-[--milkylb-color-neutral-600]">
            <FormattedMessage {...messages.title} />
          </h2>
          <AccountSelector onDidClick={() => navigate("/logged/dashboard")} />
        </div>
      </div>
    </DividerWithCarousel>
  );
};
