import { lazy, Suspense, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingAnimation from "components/LoadingAnimation";
import { UserContext } from "providers/UserProvider";
import { FadeInRouter } from "components/FadeInRouter";
import { LoggedRoutesProps } from "./types";
import { NavBar } from "./NavBar";
import { LocationContext } from "providers/LocationProvider";
import classNames from "classnames";
import { OnBoardingRoutes } from "./OnBoardingRoutes";
import IdleTimer from "components/IdleTimer";
import TermsAndConditionsMainModal from "components/TermsAndConditionsMainModal";
import InfoRequestModal from "components/InfoRequestModal";
import { SelectAccountPage } from "./SelectAccountPage";
import { PermissionsProvider } from "providers/PermissionProvider";
import {
  RouteWithPermissionViewer,
  RouteWithPermissionAdmin,
} from "components/RouteWithPermission";
import { InvalidPermissionsPage } from "./InvalidPermissionsPage";

const MultipleWithdrawRoutes = lazy(async () => ({
  default: (await import("./MultipleWithdrawRoutes")).MultipleWithdrawRoutes,
}));
const WithdrawRoutes = lazy(async () => ({
  default: (await import("./WithdrawRoutes")).WithdrawRoutes,
}));
const DepositRoutes = lazy(async () => ({
  default: (await import("./DepositRoutes")).DepositRoutes,
}));
const ExchangeRoutes = lazy(async () => ({
  default: (await import("./ExchangeRoutes")).ExchangeRoutes,
}));
const DashboardRoutes = lazy(async () => ({
  default: (await import("./DashboardRoutes")).DashboardRoutes,
}));
const BalanceRoutes = lazy(async () => ({
  default: (await import("./BalanceRoutes")).BalanceRoutes,
}));
const ProfileRoutes = lazy(async () => ({
  default: (await import("./ProfileRoutes")).ProfileRoutes,
}));
const DollarizeRoutes = lazy(async () => ({
  default: (await import("./DollarizeRoutes")).DollarizeRoutes,
}));
const HistoryRoutes = lazy(async () => ({
  default: (await import("./HistoryRoutes")).HistoryRoutes,
}));
const MarketPricesRoutes = lazy(async () => ({
  default: (await import("./MarketPricesRoutes")).MarketPricesRoutes,
}));
const CardsRoutes = lazy(async () => ({
  default: (await import("./CardsRoutes")).CardsRoutes,
}));

export const LoggedRoutes: React.FC<LoggedRoutesProps> = ({ prefix }) => {
  const { user, loading, auth0isAuthenticated, getUser } =
    useContext(UserContext);
  const { displayLocation } = useContext(LocationContext);

  useEffect(() => {
    if (user === undefined && !loading) {
      getUser();
    }
  }, [getUser, loading, user]);

  if ((loading || auth0isAuthenticated) && user === undefined) {
    return <LoadingAnimation />;
  }

  // TODO: Hay que revisar fuertemente cuando ya sabemos que un usuario esta logueado y cuando no.
  // if (!loading && !user && !auth0isAuthenticated) {
  //   return <Navigate to="/login" replace />;
  // }

  const shouldDisplayOnboarding =
    !displayLocation.pathname.startsWith("/logged/onboarding");

  const shouldDisplayAccountSelector = !displayLocation.pathname.startsWith(
    "/logged/select-account",
  );

  const shouldDisplayInvalidPermissions = !displayLocation.pathname.startsWith(
    "/logged/invalid-permissions",
  );

  return (
    user &&
    user.permission && (
      <PermissionsProvider permission={user.permission}>
        <div className="flex h-full">
          {shouldDisplayOnboarding &&
            shouldDisplayAccountSelector &&
            shouldDisplayInvalidPermissions && <NavBar />}
          <IdleTimer />
          <TermsAndConditionsMainModal />
          {/* <JuridicalPersonReminderModal /> */}
          <InfoRequestModal />
          <FadeInRouter
            className={classNames(
              "flex min-w-0 grow flex-col",
              !displayLocation.pathname.startsWith("/logged/dashboard") &&
                shouldDisplayOnboarding &&
                shouldDisplayAccountSelector &&
                shouldDisplayInvalidPermissions &&
                "pb-10 pl-5 pr-10 pt-10",
            )}
            prefix={prefix}
          >
            <Suspense fallback={<LoadingAnimation />}>
              <Routes>
                <Route
                  path="/multiples-withdraw/*"
                  element={
                    <RouteWithPermissionAdmin>
                      <MultipleWithdrawRoutes />
                    </RouteWithPermissionAdmin>
                  }
                />

                <Route
                  path="/withdraw/*"
                  element={
                    <RouteWithPermissionAdmin>
                      <WithdrawRoutes />
                    </RouteWithPermissionAdmin>
                  }
                />

                <Route
                  path="/deposit/*"
                  element={
                    <RouteWithPermissionViewer>
                      <DepositRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/exchange/*"
                  element={
                    <RouteWithPermissionAdmin>
                      <ExchangeRoutes />
                    </RouteWithPermissionAdmin>
                  }
                />

                <Route
                  path="/dashboard/*"
                  element={
                    <RouteWithPermissionViewer>
                      <DashboardRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/balance/*"
                  element={
                    <RouteWithPermissionViewer>
                      <BalanceRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/profile/*"
                  element={
                    <RouteWithPermissionViewer>
                      <ProfileRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/dollarize/*"
                  element={
                    <RouteWithPermissionAdmin>
                      <DollarizeRoutes />
                    </RouteWithPermissionAdmin>
                  }
                />

                <Route
                  path="/onboarding/*"
                  element={
                    <RouteWithPermissionViewer>
                      <OnBoardingRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/history/*"
                  element={
                    <RouteWithPermissionViewer>
                      <HistoryRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/market-prices/*"
                  element={
                    <RouteWithPermissionAdmin>
                      <MarketPricesRoutes />
                    </RouteWithPermissionAdmin>
                  }
                />

                <Route
                  path="/cards/*"
                  element={
                    <RouteWithPermissionViewer>
                      <CardsRoutes />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/select-account"
                  element={
                    <RouteWithPermissionViewer>
                      <SelectAccountPage />
                    </RouteWithPermissionViewer>
                  }
                />

                <Route
                  path="/invalid-permissions"
                  element={<InvalidPermissionsPage />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/logged/dashboard" replace={true} />}
                />
              </Routes>
            </Suspense>
          </FadeInRouter>
        </div>
      </PermissionsProvider>
    )
  );
};
