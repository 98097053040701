import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { Button, Card, Input } from "@letsbit/milkylb";

import { formMessages } from "utils/formMessages";
import {
  civilStatusOptions,
  messages,
  professionStatusOptions,
} from "../messages";
import { DataFirstStepProps } from "../types";
import { useOnLeavePage } from "hooks/utils/useOnLeavePage";
import { GoBackModal } from "./GoBackModal";
import { useNavBlocker } from "hooks/utils/useNavBlocker";
import { EmailSchema } from "utils/validators";
import { AddressInput } from "components/AddressInput";
import { CountrySelector } from "components/CountrySelector";
import { ModalSelector } from "components/ModalSelector";

export const DataFirstStep: React.FC<DataFirstStepProps> = ({
  onNext,
  control,
  setShowSubmit,
}) => {
  const { formatMessage } = useIntl();
  const [goBackModal, setGoBackModal] = useState(false);
  const [changePerformed, setChangePerformed] = useState(false);
  const blockRef = useNavBlocker({ enabled: changePerformed });

  useEffect(() => {
    setShowSubmit(false);
  }, [setShowSubmit]);

  useOnLeavePage({
    onUnloadPage: useCallback(
      (e: BeforeUnloadEvent) => {
        if (changePerformed) {
          e.preventDefault();
        }
      },
      [changePerformed],
    ),
    onBackPage: useCallback(
      (e: PopStateEvent) => {
        e.preventDefault();

        if (!goBackModal) {
          setTimeout(() => {
            setGoBackModal(true);
          }, 300);
        }
      },
      [goBackModal],
    ),
  });

  const onChangeWrapper = (handler: (...event: any[]) => any) => {
    return (...event: any) => {
      setChangePerformed(true);
      return handler(...event);
    };
  };

  return (
    <form className="flex h-full flex-col">
      <GoBackModal
        open={goBackModal}
        setOpen={setGoBackModal}
        onConfirm={() => {
          blockRef.current?.confirm();
        }}
        onCancel={() => {
          blockRef.current?.cancel();
        }}
      />
      <div className="grid h-full w-full grid-cols-3 gap-8">
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.nameLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(messages.namePlaceholder)}
                error={!!error}
                message={error?.message}
                onBlur={onBlur}
              />
            </div>
          )}
          key="first_name"
          name="first_name"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.lastNameLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(messages.lastNamePlaceholder)}
                error={!!error}
                message={error?.message}
                onBlur={onBlur}
              />
            </div>
          )}
          key="last_name"
          name="last_name"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.countryLabel} />
              </label>
              <CountrySelector
                onChange={onChangeWrapper((c) =>
                  onChange({
                    target: {
                      value: c.label,
                    },
                  }),
                )}
                value={value}
                error={error?.message}
                title={formatMessage(messages.countryLabel)}
                placeholder={formatMessage(messages.countryPlaceholder)}
                onBlur={onBlur}
              />
            </div>
          )}
          key="nationality"
          name="nationality"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.addressLabel} />
              </label>
              <AddressInput
                onSelect={onChangeWrapper(onChange)}
                place={value}
                error={!!error}
                message={error?.message}
                placeholder={formatMessage(messages.addressPlaceholder)}
                dataCypressId="bank-address-input"
                onBlur={onBlur}
              />
            </div>
          )}
          key="address"
          name="address"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.civilStateLabel} />
              </label>
              <ModalSelector
                title={
                  <h3 className="mb-8 text-center text-xl font-bold">
                    {formatMessage(messages.civilStateLabel)}
                  </h3>
                }
                options={civilStatusOptions}
                onSelectOption={(p) =>
                  onChangeWrapper(onChange)({ target: { value: p.id } })
                }
                value={
                  value &&
                  formatMessage(
                    civilStatusOptions.find((v) => v.id === value)
                      ?.label as MessageDescriptor,
                  )
                }
                placeholder={formatMessage(messages.civilStatePlaceholder)}
                error={error?.message}
                onBlur={onBlur}
                optionComponent={({ element }) => (
                  <Card
                    className="shrink-0"
                    key={element.id}
                    title={formatMessage(element.label)}
                  />
                )}
              />
            </div>
          )}
          name="civil_status"
        />

        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.professionLabel} />
              </label>
              <ModalSelector
                title={
                  <h3 className="mb-8 text-center text-xl font-bold">
                    {formatMessage(messages.professionLabel)}
                  </h3>
                }
                options={professionStatusOptions}
                onSelectOption={(p) =>
                  onChangeWrapper(onChange)({ target: { value: p.id } })
                }
                value={
                  value &&
                  formatMessage(
                    professionStatusOptions.find((v) => v.id === value)
                      ?.label as MessageDescriptor,
                  )
                }
                placeholder={formatMessage(messages.professionPlaceholder)}
                error={error?.message}
                onBlur={onBlur}
                optionComponent={({ element }) => (
                  <Card
                    className="shrink-0"
                    key={element.id}
                    title={formatMessage(element.label)}
                  />
                )}
              />
            </div>
          )}
          key="profession"
          name="profession"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.phoneLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(messages.phonePlaceholder)}
                error={!!error}
                message={error?.message}
                onBlur={onBlur}
              />
            </div>
          )}
          key="phone"
          name="phone"
        />
        <Controller
          control={control}
          rules={{
            required: formatMessage(formMessages.requiredFieldError),
            validate: (email) => {
              return (
                EmailSchema.safeParse(email).success ||
                formatMessage(formMessages.emailFieldError)
              );
            },
          }}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error },
          }) => (
            <div>
              <label className="grid-flow-row text-lg font-normal text-[--color-neutral-0]">
                <FormattedMessage {...messages.emailLabel} />
              </label>
              <Input
                onIonChange={onChangeWrapper(onChange)}
                value={value}
                placeholder={formatMessage(messages.emailPlaceholder)}
                error={!!error}
                message={error?.message}
                onBlur={onBlur}
              />
            </div>
          )}
          key="email"
          name="email"
        />
      </div>
      <div className="flex justify-end">
        <Button type="button" className="mr-4" onClick={onNext}>
          <FormattedMessage {...messages.next} />
        </Button>
      </div>
    </form>
  );
};
